import { Resource as ResourceType } from '../types';

export const expertInvoice: ResourceType = {
  name: 'expert_invoices',
  label: 'resource.invoices.label',
  hideResource: true,
  attributes: [
    {
      key: 'id',
      label: 'resource.invoices.field.id.label',
      type: 'text',
    },
    {
      key: 'company_name',
      label: 'resource.invoices.field.company_name.label',
      type: 'text',
    },
    {
      key: 'total_ttc',
      label: 'resource.invoices.field.total_ttc.label',
      type: 'number',
    },
    {
      key: 'total_ht',
      label: 'resource.invoices.field.total_ht.label',
      type: 'number',
    },
    {
      key: 'guid',
      label: 'resource.invoices.field.guid.label',
      type: 'text',
    },
    {
      key: 'pdfs',
      label: 'resource.invoices.field.pdfs.label',
      type: 'buttons',
    },
    {
      key: 'contract_id',
      label: 'resource.invoices.field.contract_id.label',
      type: 'reference',
      reference: 'contracts',
    },
    {
      key: 'contract_milestone_id',
      label: 'resource.invoices.field.contract_milestone_id.label',
      type: 'reference',
      reference: 'contract_milestones',
    },
    {
      key: 'due_date',
      label: 'resource.invoices.field.due_date.label',
      type: 'date',
    },
    {
      key: 'user_kind',
      label: 'resource.invoices.field.user_kind.label',
      type: 'select',
      choices: [
        {
          id: 'expert',
          name: 'resource.invoices.field.user_kind.choices.expert.label',
        },
        {
          id: 'client',
          name: 'resource.invoices.field.user_kind.choices.client.label',
        },
      ],
    },
    {
      key: 'status',
      label: 'resource.invoices.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'created',
          name: 'resource.invoices.field.status.choices.created.label',
        },
        {
          id: 'delayed',
          name: 'resource.invoices.field.status.choices.delayed.label',
        },
        {
          id: 'payed',
          name: 'resource.invoices.field.status.choices.payed.label',
        },
      ],
    },
    {
      key: 'created_at',
      label: 'resource.invoices.field.invoices_at.label',
      type: 'date',
    },
    {
      key: 'updated_at',
      label: 'resource.invoices.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'mission_id',
      label: 'resource.invoices.field.mission_id.label',
      type: 'reference',
      reference: 'missions',
    },
    {
      key: 'proposal_id',
      label: 'resource.invoices.field.proposal_id.label',
      type: 'reference',
      reference: 'proposals',
    },
    {
      key: 'company_id',
      label: 'resource.invoices.field.company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'client_company_id',
      label: 'resource.invoices.field.client_company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'leader_id',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'client_owner_id',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'contract_milestone',
      type: 'object',
      shape: [
        {
          key: 'payment_period',
          label: 'resource.contract_milestones.field.payment_period.label',
          type: 'select',
          choices: [
            {
              id: 'forty_five_days',
              name: 'resource.contract_milestones.field.payment_period.choices.forty_five_days_x.label',
            },
            {
              id: 'thirty_days',
              name: 'resource.contract_milestones.field.payment_period.choices.thirty_days_x.label',
            },
            {
              id: 'fifteen_days',
              name: 'resource.contract_milestones.field.payment_period.choices.fifteen_days_x.label',
            },
            {
              id: 'seventy_two_hours',
              name: 'resource.contract_milestones.field.payment_period.choices.seventy_two_hours_x.label',
            },
          ],
        },
        {
          key: 'discount',
          label: 'resource.proposals.field.discount.label',
          type: 'number',
        },
        {
          key: 'vat',
          label: 'resource.contract_milestones.field.vat.label',
          type: 'number',
        },
        {
          key: 'professional_indemnity_percentage',
          label:
            'resource.proposals.field.professional_indemnity_percentage.label',
          type: 'number',
        },
        {
          key: 'professional_indemnity',
          label: 'resource.proposals.field.professional_indemnity.label',
          type: 'boolean',
        },
      ],
    },
    {
      key: 'billing_date',
      type: 'date',
      label: 'resource.invoices.field.invoices_at.label',
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      editAndCreateBasePath: '/invoices',
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      bulkActionButtons: [
        {
          name: 'validate',
          type: 'update',
          label: 'resource.invoices.bulk_action_buttons.validate.label',
          values: {
            validated: true,
          },
        },
      ],
      filters: [
        {
          source: 'status',
          type: 'multipleSelect',
          optionsFilter: {
            className: 'multipleSelectFilter',
          },
        },
        {
          name: 'company_id',
          type: 'reference',
          reference: 'companies',
          label: 'resource.invoices.field.company_id.label',
          filter: { user_kind: 'expert' },
          child: {
            type: 'autocomplete',
            optionText: 'name',
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
      ],
      fields: [
        {
          source: 'guid',
        },
        {
          source: 'company_name',
        },
        {
          source: 'status',
        },
        {
          source: 'user_kind',
        },
        {
          source: 'due_date',
        },
        {
          source: 'pdfs',
          sortable: false,
          extensions: [
            {
              key: 'multipleLangFiles',
            },
          ],
        },
        {
          source: 'total_ht',
        },
        {
          source: 'total_ttc',
        },
        {
          source: 'contract_milestone_id',
          child: {
            source: 'contract_milestones.title',
          },
        },
        {
          source: 'contract_id',
          child: {
            source: 'contracts.id',
          },
        },
        {
          source: 'billing_date',
        },
      ],
    },
    edit: {
      actions: [],
      left: {
        tabs: [],
      },
      right: [
        {
          sections: [],
        },
      ],
    },
  },
};
