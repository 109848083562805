import withUserKindDisabledChoices from './withUserKindDisabledChoices';
import withUserKindValidationModal from './withUserKindValidationModal';
import withMissionAndCompanyName from './withMissionAndCompanyName';
import withAuthorType from './withAuthorType';
import withMultiplyByHundred from './withMultiplyByHundred';
import withModifiedLabel from './withModifiedLabel';
import withLimitedList from './withLimitedList';
import withTranslation from './withTranslation';
import withCatLengthValidation from './withCatLengthValidation';
import withSort from './withSort';
import withSuperAdminPermission from './withSuperAdminPermission';
import withDisabledCandidacyStatus from './withDisabledCandidacyStatus';
import withDisabledByDate from './withDisabledByDate';

const extensions = {
  authorType: withAuthorType,
  disabledChoices: withUserKindDisabledChoices,
  limitedList: withLimitedList,
  missionAndCompanyName: withMissionAndCompanyName,
  modal: withUserKindValidationModal,
  modifiedLabel: withModifiedLabel,
  multiplyByHundredInput: withMultiplyByHundred,
  translation: withTranslation,
  sort: withSort,
  catLengthValidation: withCatLengthValidation,
  superAdminPermission: withSuperAdminPermission,
  disabledCandidacyStatus: withDisabledCandidacyStatus,
  disabledByDate: withDisabledByDate,
};

export default extensions;
