import { Resource as ResourceType } from '../types';

export const document: ResourceType = {
  name: 'documents',
  label: 'resource.documents.label',
  hideResource: false,
  attributes: [
    {
      key: 'id',
      label: 'resource.documents.field.id.label',
      type: 'text',
    },
    {
      key: 'name',
      label: 'resource.documents.field.name.label',
      type: 'text',
    },
    {
      key: 'description',
      label: 'resource.documents.field.description.label',
      type: 'text',
    },
    {
      key: 'kind_name',
      label: 'resource.documents.field.kind.label',
      type: 'tag',
    },
    {
      key: 'validity_period',
      label: 'resource.documents.field.validity_period.label',
      type: 'select',
      choices: [
        {
          id: 'unlimited',
          name: 'resource.documents.field.validity_period.choices.unlimited.label',
        },
        {
          id: 'duration_from_file_date',
          name: 'resource.documents.field.validity_period.choices.duration_from_file_date.label',
        },
      ],
    },
    {
      key: 'duration',
      label: 'resource.documents.field.duration.label',
      type: 'number',
    },
    {
      key: 'user_kind',
      label: 'resource.documents.field.user_kind.label',
      type: 'select',
      choices: [
        {
          id: 'expert',
          name: 'resource.documents.field.user_kind.choices.expert.label',
        },
        {
          id: 'client',
          name: 'resource.documents.field.user_kind.choices.client.label',
        },
        {
          id: 'mixed',
          name: 'resource.documents.field.user_kind.choices.mixed.label',
        },
      ],
    },
    {
      key: 'condition',
      label: 'resource.documents.field.condition.label',
      type: 'select',
      choices: [
        {
          id: 'optional',
          name: 'resource.documents.field.condition.choices.optional.label',
        },
        {
          id: 'mandatory_to_apply',
          name: 'resource.documents.field.condition.choices.mandatory_to_apply.label',
        },
        {
          id: 'mandatory_to_send_proposal',
          name: 'resource.documents.field.condition.choices.mandatory_to_send_proposal.label',
        },
        {
          id: 'mandatory_to_validate_invoice',
          name: 'resource.documents.field.condition.choices.mandatory_to_validate_invoice.label',
        },
      ],
    },
    {
      key: 'client_condition',
      label: 'resource.documents.field.client_condition.label',
      type: 'select',
      choices: [
        {
          id: 'optional',
          name: 'resource.documents.field.client_condition.choices.optional.label',
        },
        {
          id: 'mandatory_to_publish_mission',
          name: 'resource.documents.field.client_condition.choices.mandatory_to_publish_mission.label',
        },
      ],
    },
    {
      key: 'sectors',
      label: 'resource.companies.field.sectors.label',
      type: 'multipleSelect',
      choices: [
        {
          id: 'automative',
          name: 'resource.companies.field.sectors.choices.automative.label',
        },
        {
          id: 'aeronautics',
          name: 'resource.companies.field.sectors.choices.aeronautics.label',
        },
        {
          id: 'space',
          name: 'resource.companies.field.sectors.choices.space.label',
        },
        {
          id: 'defense',
          name: 'resource.companies.field.sectors.choices.defense.label',
        },
        {
          id: 'railway',
          name: 'resource.companies.field.sectors.choices.railway.label',
        },
        {
          id: 'telecoms',
          name: 'resource.companies.field.sectors.choices.telecoms.label',
        },
        {
          id: 'energy',
          name: 'resource.companies.field.sectors.choices.energy.label',
        },
        {
          id: 'services_and_information_systems',
          name: 'resource.companies.field.sectors.choices.services_and_information_systems.label',
        },
        {
          id: 'oil_and_gas',
          name: 'resource.companies.field.sectors.choices.oil_and_gas.label',
        },
        {
          id: 'naval',
          name: 'resource.companies.field.sectors.choices.naval.label',
        },
        {
          id: 'machining_or_heavy_industry',
          name: 'resource.companies.field.sectors.choices.machining_or_heavy_industry.label',
        },
        {
          id: 'bioengineering',
          name: 'resource.companies.field.sectors.choices.bioengineering.label',
        },
        {
          id: 'bank_insurance',
          name: 'resource.companies.field.sectors.choices.bank_insurance.label',
        },
        {
          id: 'infrastructure',
          name: 'resource.companies.field.sectors.choices.infrastructure.label',
        },
        {
          id: 'digital',
          name: 'resource.companies.field.sectors.choices.digital.label',
        },
      ],
    },
    {
      key: 'company_kinds',
      label: 'resource.missions.field.company_kinds.label',
      type: 'multipleSelect',
      choices: [
        {
          id: 'esn',
          name: 'resource.companies.field.kind.choices.esn.label',
        },
        {
          id: 'recruitment_candidate',
          name: 'resource.companies.field.kind.choices.recruitment_candidate.label',
        },
        {
          id: 'freelance',
          name: 'resource.companies.field.kind.choices.freelance.label',
        },
        {
          id: 'design_office',
          name: 'resource.companies.field.kind.choices.design_office.label',
        },
        {
          id: 'agency',
          name: 'resource.companies.field.kind.choices.agency.label',
        },
        {
          id: 'major_company',
          name: 'resource.companies.field.kind.choices.major_company.label',
        },
        {
          id: 'startup',
          name: 'resource.companies.field.kind.choices.startup.label',
        },
        {
          id: 'consulting',
          name: 'resource.companies.field.kind.choices.consulting.label',
        },
        {
          id: 'smes',
          name: 'resource.companies.field.kind.choices.smes.label',
        },
      ],
    },
    {
      key: 'contract_types',
      label: 'resource.companies.field.contract_types.label',
      type: 'multipleSelect',
      choices: [
        {
          id: 'fulltime_contract',
          name: 'resource.companies.field.contract_types.choices.fulltime_contract.label',
        },
        {
          id: 'internship',
          name: 'resource.companies.field.contract_types.choices.internship.label',
        },
        {
          id: 'missions',
          name: 'resource.companies.field.contract_types.choices.missions.label',
        },
        {
          id: 'missions_or_fulltime_contract',
          name: 'resource.companies.field.contract_types.choices.missions_or_fulltime_contract.label',
        },
      ],
    },
    {
      key: 'solo_collective',
      label: 'resource.documents.field.solo_collective.label',
      type: 'select',
      choices: [
        {
          id: '',
          name: 'resource.documents.field.solo_collective.choices.empty.label',
        },
        {
          id: 'solo',
          name: 'resource.documents.field.solo_collective.choices.solo.label',
        },
        {
          id: 'collective',
          name: 'resource.documents.field.solo_collective.choices.collective.label',
        },
      ],
    },
    {
      key: 'company_ids',
      label: 'resource.documents.field.company_ids.label',
      type: 'referenceArray',
      reference: 'companies',
    },
    {
      key: 'company_client_ids',
      label: 'resource.documents.field.company_ids.label',
      type: 'referenceArray',
      reference: 'companies',
    },
    {
      key: 'company_expert_ids',
      label: 'resource.documents.field.company_ids.label',
      type: 'referenceArray',
      reference: 'companies',
    },
    {
      key: 'company_mixed_ids',
      label: 'resource.documents.field.company_ids.label',
      type: 'referenceArray',
      reference: 'companies',
    },
    {
      key: 'locations',
      label: 'resource.documents.field.locations.label',
      type: 'location',
    },
    {
      key: 'attachments',
      label: 'resource.documents.field.attachments.label',
      type: 'file',
    },
    {
      key: 'sub_categories',
      label: 'resource.documents.field.sub_categories.label',
      type: 'referenceArray',
      reference: 'sub_categories',
    },
    {
      key: 'created_at',
      label: 'resource.documents.field.created_at.label',
      type: 'date',
    },
    {
      key: 'updated_at',
      label: 'resource.documents.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'sub_categories_id',
      label: 'resource.companies.field.sub_categories_name.label',
      type: 'referenceArray',
      reference: 'sub_categories',
    },
    {
      key: 'status',
      label: 'resource.documents.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'online',
          name: 'resource.documents.field.status.choices.online.label',
        },
        {
          id: 'archived',
          name: 'resource.documents.field.status.choices.archived.label',
        },
      ],
    },
  ],
  pages: {
    list: {
      hasCreateButton: true,
      hasEditButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      filters: [
        {
          source: 'condition',
        },
      ],
      fields: [
        {
          source: 'name',
        },
        {
          source: 'kind_name',
        },
        {
          source: 'condition',
        },
        {
          source: 'client_condition',
        },
        {
          source: 'status',
        },
      ],
      bulkActionButtons: [],
    },
    create: {
      left: {
        tabs: [
          {
            key: 'documents',
            title: 'resource.documents.tab.title',
            sections: [
              {
                key: 'users',
                title: 'resource.documents.section.create.title',
                layout: [
                  ['name'],
                  ['description'],
                  ['attachments'],
                  ['kind_name'],
                  ['validity_period'],
                  ['duration'],
                  ['user_kind'],
                  ['condition'],
                  ['client_condition'],
                ],
                inputs: {
                  name: {
                    validators: [{ key: 'required' }],
                  },
                  description: {},
                  attachments: {},
                  kind_name: {
                    validators: [{ key: 'required' }],
                  },
                  validity_period: {
                    validators: [{ key: 'required' }],
                  },
                  duration: {
                    validators: [
                      {
                        key: 'required',
                        rules: [
                          {
                            property: 'validity_period',
                            op: 'eq',
                            value: 'duration_from_file_date',
                          },
                        ],
                      },
                    ],
                  },
                  user_kind: {
                    validators: [{ key: 'required' }],
                  },
                  condition: {
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                  },
                  client_condition: {
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'target',
                title: 'resource.documents.section.target.create.title',
                layout: [
                  ['locations'],
                  ['sectors'],
                  ['sub_category'],
                  ['company_kinds'],
                  ['contract_types'],
                  ['solo_collective'],
                  ['company_expert_ids'],
                  ['company_client_ids'],
                  ['company_mixed_ids'],
                ],
                inputs: {
                  locations: {},
                  sectors: {},
                  sub_category: {
                    source: 'sub_categories_id',
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                    child: {
                      type: 'autocompleteArray',
                      optionText: 'sub_categories_name_phrase',
                      options: {
                        perPage: 108,
                        allowEmpty: false,
                      },
                      extensions: [
                        {
                          key: 'sort',
                          params: {
                            type: 'alphabetic',
                            mode: 'asc',
                          },
                        },
                      ],
                    },
                  },
                  company_kinds: {},
                  contract_types: {
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  solo_collective: {},
                  company_expert_ids: {
                    filter: { user_kind: 'expert' },
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'mixed',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                    child: {
                      type: 'autocompleteArray',
                      optionText: 'name',
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                  },
                  company_client_ids: {
                    filter: { user_kind: 'client', administrator: 'false' },
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'mixed',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                    child: {
                      type: 'autocompleteArray',
                      optionText: 'name',
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                  },
                  company_mixed_ids: {
                    filter: { administrator: 'false' },
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                    child: {
                      type: 'autocompleteArray',
                      optionText: 'name',
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                  },
                },
              },
            ],
          },
        ],
      },
    },
    edit: {
      singleRedirect: {
        page: 'edit',
        basePath: '/documents',
        recordId: 'id',
      },
      actions: [
        {
          name: 'archive',
          type: 'update',
          label: 'resource.documents.bulk_action_buttons.archive.label',
          values: { archived: true },
          withConfirmation: true,
          confirmation: {
            content: 'modal.archive.documents.content',
          },
          disabled: [{ property: 'status', op: 'eq', value: 'archived' }],
        },
        {
          name: 'unarchive',
          type: 'update',
          label: 'resource.documents.bulk_action_buttons.online.label',
          values: { archived: false },
          withConfirmation: true,
          confirmation: {
            content: 'modal.unarchive.documents.content',
          },
          disabled: [{ property: 'status', op: 'eq', value: 'online' }],
        },
      ],
      left: {
        tabs: [
          {
            key: 'document',
            title: 'resource.document.tab.information.title',
            sections: [
              {
                key: 'document',
                title: 'resource.document.section.information.title',
                layout: [
                  ['name'],
                  ['description'],
                  ['attachments'],
                  ['kind_name'],
                  ['validity_period'],
                  ['duration'],
                  ['user_kind'],
                  ['condition'],
                  ['client_condition'],
                ],
                inputs: {
                  name: {
                    validators: [{ key: 'required' }],
                  },
                  description: {},
                  attachments: {},
                  kind_name: {
                    validators: [{ key: 'required' }],
                  },
                  validity_period: {
                    validators: [{ key: 'required' }],
                  },
                  duration: {
                    validators: [
                      {
                        key: 'required',
                        rules: [
                          {
                            property: 'validity_period',
                            op: 'eq',
                            value: 'duration_from_file_date',
                          },
                        ],
                      },
                    ],
                  },
                  user_kind: {
                    validators: [{ key: 'required' }],
                  },
                  condition: {
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                  },
                  client_condition: {
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'document_target',
                title: 'resource.document_target.section.information.title',
                layout: [
                  ['locations'],
                  ['sectors'],
                  ['sub_category'],
                  ['company_kinds'],
                  ['contract_types'],
                  ['solo_collective'],
                  ['company_expert_ids'],
                  ['company_client_ids'],
                  ['company_mixed_ids'],
                ],
                inputs: {
                  locations: {},
                  sectors: {},
                  sub_category: {
                    source: 'sub_categories_id',
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                    child: {
                      type: 'autocompleteArray',
                      optionText: 'sub_categories_name_phrase',
                      options: {
                        perPage: 108,
                        allowEmpty: false,
                      },
                      extensions: [
                        {
                          key: 'sort',
                          params: {
                            type: 'alphabetic',
                            mode: 'asc',
                          },
                        },
                      ],
                    },
                  },
                  company_kinds: {},
                  contract_types: {
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  solo_collective: {},
                  company_expert_ids: {
                    filter: { user_kind: 'expert' },
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'mixed',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                    child: {
                      type: 'autocompleteArray',
                      optionText: 'name',
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                  },
                  company_client_ids: {
                    filter: { user_kind: 'client', administrator: 'false' },
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'mixed',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                    child: {
                      type: 'autocompleteArray',
                      optionText: 'name',
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                  },
                  company_mixed_ids: {
                    filter: { administrator: 'false' },
                    rules: {
                      hide: [
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                    child: {
                      type: 'autocompleteArray',
                      optionText: 'name',
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                  },
                },
                fields: {},
              },
            ],
          },
          {
            key: 'experts',
            title: 'resource.document.tab.experts.title',
            sections: [
              {
                key: 'experts',
                title: 'resource.document.section.information.experts.title',
                layout: [],
                inputs: {},
                list: {
                  hasEditButton: false,
                  actions: [],
                  resource_to_fetch: 'documents_companies',
                  filter_resource: 'document',
                  filter_attribute: 'id',
                  fields: [
                    {
                      source: 'company_leader_id',
                      child: {
                        source: 'users.full_name',
                      },
                    },
                    {
                      source: 'company_leader_company_name',
                    },
                    {
                      source: 'company_leader_job_name',
                    },
                    {
                      source: 'status',
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.document.section.details.title',
              layout: [['id'], ['created_at', 'updated_at'], ['status']],
              inputs: {
                id: {
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                  width: 6,
                },
                updated_at: {
                  disabled: true,
                  width: 6,
                },
                status: {
                  disabled: true,
                },
              },
            },
          ],
        },
      ],
    },
  },
};
